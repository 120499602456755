import "./App.css";
import Tooltip from "./components/Tooltip/Tooltip";
import { ReactComponent as Obss } from "./resources/companies/c1-ob.svg";
import Witwiser from "./resources/companies/c2-wit.jpg";
import profilePhoto from "./resources/profilePhoto.jpeg";
import { ReactComponent as AndroidIcon } from "./resources/skills/android-48.svg";
import { ReactComponent as ArduinoIcon } from "./resources/skills/arduino-48.svg";
import { ReactComponent as AwsIcon } from "./resources/skills/aws-48.svg";
import { ReactComponent as DockerIcon } from "./resources/skills/docker-48.svg";
import { ReactComponent as GitIcon } from "./resources/skills/git-48.svg";
import { ReactComponent as GraphqlIcon } from "./resources/skills/graphql-48.svg";
import { ReactComponent as InfluxIcon } from "./resources/skills/influx-48.svg";
import { ReactComponent as JavaIcon } from "./resources/skills/java-48.svg";
import { ReactComponent as JavascriptIcon } from "./resources/skills/javascript-48.svg";
import { ReactComponent as JenkinsIcon } from "./resources/skills/jenkins-48.svg";
import { ReactComponent as JiraIcon } from "./resources/skills/jira-48.svg";
import { ReactComponent as KafkaIcon } from "./resources/skills/kafka-48.svg";
import { ReactComponent as MysqlIcon } from "./resources/skills/mysql-48.svg";
import { ReactComponent as NodejsIcon } from "./resources/skills/nodejs-48.svg";
import { ReactComponent as PhpIcon } from "./resources/skills/php-48.svg";
import { ReactComponent as ReactIcon } from "./resources/skills/react-48.svg";
import { ReactComponent as XamarinIcon } from "./resources/skills/xamarin-48.svg";

function App() {
  return (
    <div className="container bg-white mx-auto p-8 m-8 md:mx-auto md:p-12 md:m-12 print:m-0 print:mx-auto print:w-full print:max-w-full">
      <hr className="Divider hidden" />
      <div className="headerSection flex items-center">
        <div className="PhotoContainer"><img class="w-[100px] h-[100px] rounded-full" src={profilePhoto} alt="Barış Aytekin" /></div>
        <header>
          <h1 className="Name">Barış Aytekin</h1>
          <span className="Title">Associate Architect at OBSS - Atlassian APSS Group</span>
        </header>
      </div>
      <hr />
      <div className="grid grid-cols-1 gap-1 md:gap-12 md:grid-cols-12 print:gap-12 print:grid-cols-12">
        <div className="col-span-12 md:col-span-9 print:col-span-9">
          <section className="Section">
            <div className="SectionHeading">EXPERIENCE</div>
            <div>
              <div className="timeline">
                <div className="flex flex-row space-x-2">
                  <Obss className="w-[48px]" />
                  <div className="flex flex-col self-center">
                    <span className="font-semibold">OBSS</span>
                  </div>
                </div>
                <ul>
                  <li>
                    <div className="content">
                      <div className="items">
                        <span className="font-semibold text-base">
                          Associate Architect - Atlassian APSS Group
                        </span>
                        <span className="font-normal text-sm text-black/[.9]">
                          Full-time
                        </span>
                        <span className="font-light text-sm text-black/[.6]">
                          Mar 2024 - Present
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <div className="items">
                        <span className="font-semibold text-base">
                          Senior Consultant - Atlassian APSS Group
                        </span>
                        <span className="font-normal text-sm text-black/[.9]">
                          Full-time
                        </span>
                        <span className="font-light text-sm text-black/[.6]">
                          May 2023 - Apr 2024 · 1 yr
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="timeline">
                <div className="flex flex-row space-x-2">
                  <img className="w-[48px]" src={Witwiser} />
                  <div className="flex flex-col">
                    <span className="font-semibold">Witwiser</span>
                    <span className="font-normal text-sm text-black/[.9]">
                      1 yr 9 mos
                    </span>
                  </div>
                </div>
                <ul>
                  <li>
                    <div className="content">
                      <div className="items">
                        <span className="font-semibold text-base">
                          Mid-Senior Software Engineer
                        </span>
                        <span className="font-normal text-sm text-black/[.9]">
                          Full-time
                        </span>
                        <span className="font-light text-sm text-black/[.6]">
                          Sep 2021 - May 2023 · 1 yr 9 mos
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="timeline">
                <div className="flex flex-row space-x-2">
                  <Obss className="w-[48px]" />
                  <div className="flex flex-col">
                    <span className="font-semibold">OBSS</span>
                    <span className="font-normal text-sm text-black/[.9]">
                      3 yrs 4 mos
                    </span>
                  </div>
                </div>
                <ul>
                  <li>
                    <div className="content">
                      <div className="items">
                        <span className="font-semibold text-base">
                          Consultant
                        </span>
                        <span className="font-normal text-sm text-black/[.9]">
                          Full-time
                        </span>
                        <span className="font-light text-sm text-black/[.6]">
                          Jan 2021 - Sep 2021 · 9 mos
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <div className="items">
                        <span className="font-semibold text-base">
                          Associate Consultant
                        </span>
                        <span className="font-normal text-sm">Full-time</span>
                        <span className="font-light text-sm">
                          Jan 2019 - Jan 2021 · 2 yrs 1 mo
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <div className="items">
                        <span className="font-bold text-base">
                          Long Term Intern
                        </span>
                        <span className="font-normal text-sm">Part-time</span>
                        <span className="font-light text-sm">
                          Aug 2018 - Dec 2018 · 5 mos
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <div className="items">
                        <span className="font-bold text-base">Intern</span>
                        <span className="font-normal text-sm">Full-time</span>
                        <span className="font-light text-sm">
                          Jun 2018 - Aug 2018 · 3 mos
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="Section">
            <div className="SectionHeading">PROJECTS</div>
            <div className="Item">
              <div className="ItemHeading">
                <h4 className="ItemTitle leading-7">Time In Status</h4>
                <div className="ItemTitleInfo">
                  <div className="ItemTitleTechStack">
                    <Tooltip content="Java">
                      <JavaIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="MySQL">
                      <MysqlIcon className="w-8 h-8 leading-7" />
                    </Tooltip>
                    <Tooltip content="React">
                      <ReactIcon className="w-6 h-6 leading-7" />
                    </Tooltip>
                    <Tooltip content="Jira">
                      <JiraIcon className="w-5 h-5 leading-7" />
                    </Tooltip>
                  </div>
                  <div className="float-left leading-7">
                    OBSS | May 2023 - Present
                  </div>
                </div>
              </div>
              <p>
                The jira plugin that you can evaluate average time tasks spend in each status, identify bottlenecks in the workflow by analyzing where tasks spend the most time, calculate the total time spent on tasks in a given period etc.
              </p>
            </div>
            <hr />
            <div className="Item">
              <div className="ItemHeading">
                <h4 className="ItemTitle leading-7">
                  Witwiser / Assessment System
                </h4>
                <div className="ItemTitleInfo">
                  <div className="ItemTitleTechStack">
                    <Tooltip content="Java">
                      <JavaIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="MySQL">
                      <MysqlIcon className="w-8 h-8 leading-7" />
                    </Tooltip>
                    <Tooltip content="Apache Kafka">
                      <KafkaIcon className="w-6 h-6 leading-7" />
                    </Tooltip>
                    <Tooltip content="React">
                      <ReactIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="Graphql">
                      <GraphqlIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="AWS">
                      <AwsIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                  </div>
                  <div className="float-left leading-7">
                    Witwiser | Mar 2019 - May 2023
                  </div>
                </div>
              </div>
              <p>
                Online assessment system which simplifies the process for both test-takers and institutions, administers your online tests, offering various question types and difficulty levels.
                Additionally, you can organize question pools, select questions randomly, and conveniently schedule online exams.
              </p>
            </div>
            <hr />
            <div className="Item">
              <div className="ItemHeading">
                <h4 className="ItemTitle leading-7">
                  Witwiser / Proctoring System
                </h4>
                <div className="ItemTitleInfo">
                  <div className="ItemTitleTechStack">
                    <Tooltip content="Java">
                      <JavaIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="NodeJS">
                      <NodejsIcon className="w-8 h-8 leading-7" />
                    </Tooltip>
                    <Tooltip content="Apache Kafka">
                      <KafkaIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="MySQL">
                      <MysqlIcon className="w-8 h-8 leading-7" />
                    </Tooltip>
                    <Tooltip content="InfluxDB">
                      <InfluxIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="ReactJS">
                      <ReactIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="Graphql">
                      <GraphqlIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="AWS">
                      <AwsIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                  </div>
                  <div className="float-left leading-7">
                    Witwiser | Feb 2019 - May 2023
                  </div>
                </div>
              </div>
              <p>
                Project’s main objective is the realization of proctored test as
                a mechanism to ensure the authenticity of the test taker and
                prevent him/her from cheating via AI proctoring. Main usage
                areas include: Hiring Processes, Educational Examination
                Systems, Online Certifications
              </p>
              <p>
                Our system consists of a web based client, processing engine,
                analytic engine, backend for frontend, and a chrome plugin to
                access the devices properties and I/O & traffic events.
              </p>
              <p>
                Main design principle is to be user friendly and reliable enough
                to prevent cheating. The system can be used in parallel with
                human assisted proctoring even if it does not solely require any
                human assistance to cover most of the use cases. Generated
                events and alarms are fully configurable to be used within
                different usage areas and can be monitored in real time or can
                be reviewed after the session.
              </p>
              <p>
                AI based automated proctoring enables analyzing environmental
                and behavioural patterns to assess the possibility of cheating
                in any given test scenario.
              </p>
              <p>
                The system can be connected to any application through the API
                which enables high level abstraction and easy setup for the
                clients.
              </p>
            </div>
            <hr />
            <div className="Item">
              <div className="ItemHeading">
                <h4 className="ItemTitle">
                  PMS - Performance Management System
                </h4>
                <div className="ItemTitleInfo">
                  <div className="ItemTitleTechStack">
                    <Tooltip content="Java">
                      <JavaIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="MySQL">
                      <MysqlIcon className="w-8 h-8 leading-7" />
                    </Tooltip>
                    <Tooltip content="ReactJS">
                      <ReactIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                  </div>
                  <div className="float-left leading-7">
                    OBSS | Jul 2018 - Feb 2019
                  </div>
                </div>
              </div>
              <p>
                Responsive web application that is compatible with mobile. In
                the application, there are accepted feedback, morale, scheculed
                feedback features.
              </p>
            </div>
            <hr />
            <div className="Item">
              <div className="ItemHeading">
                <h4 className="ItemTitle">Maltepe Etkinlik</h4>
                <div className="ItemTitleInfo">
                  <div className="ItemTitleTechStack">
                    <Tooltip content="PHP">
                      <PhpIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="MySQL">
                      <MysqlIcon className="w-8 h-8 leading-7" />
                    </Tooltip>
                    <Tooltip content="Xamarin">
                      <XamarinIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                  </div>
                  <div className="float-left leading-7">
                    Maltepe University | Nov 2016 - Jan 2019
                  </div>
                </div>
              </div>
              <p>
                It is a mobile and web project developed for Maltepe University
                students to quickly and efficiently get information about events
                such as seminars, concerts, excursions that have taken place at
                the university or will take place in the future.
              </p>
            </div>
            <hr />
            <div className="Item">
              <div className="ItemHeading">
                <h4 className="ItemTitle">
                  Underground Cargo Warehouse System
                </h4>
                <div className="ItemTitleInfo">
                  <div className="ItemTitleTechStack">
                    <Tooltip content="PHP">
                      <PhpIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="MySQL">
                      <MysqlIcon className="w-8 h-8 leading-7" />
                    </Tooltip>
                    <Tooltip content="Arduino">
                      <ArduinoIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                    <Tooltip content="Android">
                      <AndroidIcon className="w-7 h-7 leading-7" />
                    </Tooltip>
                  </div>
                  <div className="float-left leading-7">
                    Maltepe University | Sep 2017 - Dec 2018
                  </div>
                </div>
              </div>
              <p>
                Underground Cargo Warehouse System provides safe storage of
                cargo packages and taken by receiver. The receiver can take
                delivery of the package with shipping code and several security
                steps, after the package is added to the underground warehouse.
                The most important point in this project is that there is no
                time limit so, the sender or cargo truck can put the package at
                any time for shipping and also the receiver or cargo truck can
                take own package from the warehouse. This underground cargo
                warehouse system is our prototype station and it is a tablet and
                web project developed to manage this station logically.
              </p>
            </div>
          </section>
          <section className="Section">
            <div className="SectionHeading">HONORS & AWARDS</div>
            <div className="Item">
              <div className="ItemHeading">
                <h4 className="ItemTitle">Winner of OBSS CodeFrontation</h4>
                <div className="ItemTitleInfo">OBSS | Apr 2019</div>
              </div>
              <p>
                "OBSS Codefrontation" is a company wide competition. Main
                project purpose is the realization of proctored test as a
                mechanism to ensure the authenticity of the test taker and
                prevent him/her from cheating via AI proctoring.
              </p>
              <p>Our team which name is "Mind Readers" won the first prize.</p>
              <p>
                <a
                  href="/CodeFrontation-Design.pdf"
                  className="text-primary underline"
                >
                  You can find technical design of our project
                </a>
              </p>
            </div>
            <div className="Item">
              <div className="ItemHeading">
                <h4 className="ItemTitle">Honor Certificate</h4>
                <div className="ItemTitleInfo">
                  Maltepe University | Feb 2019
                </div>
              </div>
              <p>-</p>
            </div>
            <div className="Item">
              <div className="ItemHeading">
                <h4 className="ItemTitle">Winner Of OBSS Summer Internship</h4>
                <div className="ItemTitleInfo">OBSS | Jul 2018</div>
              </div>
              <p>
                The Summer School Program is among short-term internship
                programs organized by OBSS during the summer term. Within the
                scope of this program, a Java-based project development
                competition is organized among the students. Students
                participating in the competition are expected to create a
                project, in which all the details are developed by them, within
                four weeks. And while the students are developing their
                projects, mentors are there to provide support for their
                technical questions. On the last day of the program, the project
                presentations are done in front of a jury consisting of the OBSS
                team. The owner of the most successful project is entitled to
                receive the grand prize.
              </p>
            </div>
          </section>
        </div>
        <div className="col-span-12 md:col-span-3 print:col-span-3">
          <section className="Section">
            <div className="SectionHeading">CERTIFICATES</div>
            <div className="flex">
              <div>
                <a href="https://www.credly.com/badges/2bc2b0ab-ece7-43d6-887b-4c54a2ec001c/public_url" target="_blank" rel="noreferrer">
                  <img src="./certificates/AWS-Certified-Solutions-Architect-Associate.png" alt="AWS-Certified-Solutions-Architect-Associate" />
                </a>
              </div>
              <div>
                <a href="https://www.credly.com/badges/bd26d850-32c6-4552-875b-c58bc7da3e2e/public_url" target="_blank" rel="noreferrer">
                  <img src="./certificates/AWS-Certified-Cloud-Practitioner.png" alt="AWS-Certified-Cloud-Practitioner" />
                </a>
              </div>
            </div>
          </section>
          <section className="Section">
            <div className="SectionHeading">SKILLS</div>
            <div>
              <div className="Heading">
                <h4 className="HeadingTitle">Technical</h4>
              </div>
              <div className="flex">
                <JavaIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">Java</div>
              </div>
              <div className="flex">
                <NodejsIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">NodeJS</div>
              </div>
              <div className="flex">
                <JavascriptIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">Javascript</div>
              </div>
              <div className="flex">
                <MysqlIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">MySQL</div>
              </div>
              <div className="flex">
                <KafkaIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">Apache Kafka</div>
              </div>
              <div className="flex">
                <ReactIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">ReactJS</div>
              </div>
              <div className="flex">
                <AwsIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">AWS</div>
              </div>
              <div className="flex">
                <DockerIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">Docker</div>
              </div>
              <div className="flex">
                <JenkinsIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">Continuous Integration</div>
              </div>
              <div className="flex">
                <GitIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">Git</div>
              </div>
              <div className="flex">
                <GraphqlIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">Graphql</div>
              </div>
              <div className="flex">
                <InfluxIcon className="flex-none w-9 h-9" />
                <div className="leading-9 ml-4">InfluxDB</div>
              </div>
            </div>
          </section>
          <section className="Section">
            <div className="SectionHeading">EDUCATION</div>
            <div>
              <span className="font-semibold text-base">
                Maltepe University
              </span>
              <br />
              <span className="font-normal text-sm text-black/[.9]">
                BSc. Computer Engineering
              </span>
            </div>
          </section>
          <section className="Section">
            <div className="SectionHeading">LANGUAGES</div>
            <div>
              <div className="flex">English</div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default App;
